import buttonTypes from '@/components/button/buttonTypes';

export default {
  props: {
    type: {
      String,
      default: buttonTypes.OUTLINE,
      validator: prop => Object.values(buttonTypes).includes(prop)
    }
  }
};
