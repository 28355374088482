<template>
  <component
    :class="[$style['button'], $style[`button--${type}`]]"
    :is="link ? 'a' : 'button'"
    :href="link"
    :target="target"
  >
    <slot />
    {{ text }}
  </component>
</template>

<script>
import buttonTypeMixin from '@/components/button/buttonTypeMixin';
export default {
  name: 'Button',
  mixins: [buttonTypeMixin],
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    link: {
      type: String,
      required: false
    },
    target: {
      type: String,
      required: false,
      default: '_self'
    }
  }
};
</script>

<style module>
.button {
  @apply px-8 py-1
  bg-black
  border border-primary box-border rounded-3xl
  text-lg text-white font-bold;
}

.button--btn-filled {
  @apply bg-primary;
}

.button--btn-opacity {
  @apply bg-white bg-opacity-40
  border-none box-border;
}
</style>
