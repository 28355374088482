import registerGlobalComponents from '@/plugins/registerGlobalComponents';
import buttonTypes from '@/components/button/buttonTypes';
export default {
  install(Vue) {
    registerGlobalComponents(Vue);
    Vue.mixin({
      data() {
        return {
          buttonTypes
        };
      }
    });
  }
};
