<template>
  <div id="app" class="app-container">
    <MainMenu />
    <main class="main-container">
      <router-view />
    </main>
    <SimpleFadeTransition>
      <LanguageSwitcherOverlay v-if="$store.state.languageSwitcherOpen" />
    </SimpleFadeTransition>
  </div>
</template>

<script>
import MainMenu from '@/components/menu/MainMenu';
export default {
  name: 'App',
  components: {
    MainMenu,
    LanguageSwitcherOverlay: () =>
      import(
        /* webpackChunkName: "language-switcher" */ '@/components/language/LanguageSwitcherOverlay'
      )
  }
};
</script>

<style>
.main-container {
  /* same pt as height of MainMenu */
  @apply pt-24 w-full;
}

body {
  overflow-x: hidden;
}
</style>
