<template>
  <ContentContainer tag-name="header" :class="$style['main-menu']">
    <img
      :class="$style['main-menu__logo']"
      src="../../assets/img/main-menu/logo.png"
      alt=""
    />
    <div
      :class="$style['main-menu__language-switcher-wrapper']"
      @click="openLanguageSwitcher"
    >
      <img
        :class="$style['main-menu__language-switcher-icon']"
        src="../../assets/img/main-menu/language-switcher.svg"
        alt=""
      />
      <!--      <p :class="$style['main-menu__language-switcher-text']">-->
      <!--        {{ $i18n.locale }}-->
      <!--      </p>-->
    </div>
  </ContentContainer>
</template>

<script>
export default {
  name: 'MainMenu',
  methods: {
    openLanguageSwitcher() {
      this.$store.commit('setLanguageSwitcherOpen', true);
    }
  }
};
</script>

<style module>
.main-menu {
  @apply h-24
  fixed
  z-10
  flex justify-between items-center
  py-0
  bg-black
  w-full;
}

.main-menu__logo {
  @apply w-4/6 md:w-56;
}

.main-menu__language-switcher-wrapper {
  @apply flex flex-col items-center;
}

.main-menu__language-switcher-icon {
  @apply cursor-pointer;
}

/*.main-menu__language-switcher-text {*/
/*  @apply text-sm text-white;*/
/*}*/
</style>
