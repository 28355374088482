/**
 * @param {string} urlBase
 * @param {string} urlPath
 */
export default function concatUrl(urlBase, urlPath) {
  const pathFinal = urlPath[0] === '/' ? urlPath.slice(1) : urlPath;
  if (urlBase[urlBase.length - 1] === '/') {
    return urlBase + pathFinal;
  } else {
    return urlBase + '/' + pathFinal;
  }
}
