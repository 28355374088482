<template>
  <div class="home"></div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'Home',
  apollo: {
    product: gql`
      {
        product(id: 8) {
          id
          title_en
          product_category {
            name_en
            recipes {
              title_en
            }
          }
        }
      }
    `
  }
};
</script>

<style></style>
