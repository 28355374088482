import ContentContainer from '@/components/containers/ContentContainer';
import Button from '@/components/button/Button';
import SimpleFadeTransition from '@/components/transitions/SimpleFadeTransition';
import MediaImage from '@/components/media/MediaImage';

export default Vue => {
  Vue.component('ContentContainer', ContentContainer);
  Vue.component('Button', Button);
  Vue.component('SimpleFadeTransition', SimpleFadeTransition);
  Vue.component('MediaImage', MediaImage);
};
