import concatUrl from '@/utils/concatUrl';

export default {
  props: {
    src: {
      type: String,
      required: true
    },
    strapi: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    computedSrc() {
      if (this.strapi) {
        return concatUrl(process.env.VUE_APP_STRAPI_URL, this.src);
      } else {
        return this.src;
      }
    }
  }
};
