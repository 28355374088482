import Vue from 'vue';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-boost';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import './assets/fonts.css';
import i18n from './i18n';
import globalsPlugin from '@/plugins/globalsPlugin';
import concatUrl from '@/utils/concatUrl';

const apolloClient = new ApolloClient({
  uri: concatUrl(process.env.VUE_APP_STRAPI_URL, 'graphql')
});

Vue.config.productionTip = false;

Vue.use(VueApollo);
Vue.use(globalsPlugin);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

new Vue({
  router,
  store,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app');
