<template>
  <img :src="computedSrc" :alt="alt" />
</template>

<script>
import mediaBaseMixin from '@/components/media/mediaBaseMixin';

export default {
  name: 'MediaImage',
  mixins: [mediaBaseMixin],
  props: {
    alt: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>
