import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    languageSwitcherOpen: false
  },
  mutations: {
    setLanguageSwitcherOpen(state, payload) {
      state.languageSwitcherOpen = payload;
    }
  },
  actions: {},
  modules: {}
});
