import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/product/:productNumber',
    name: 'product-redirect',
    redirect() {
      let locale = window.navigator.userLanguage || window.navigator.language;
      if (locale.includes('-')) {
        locale = locale.split('-')[0];
      }
      if (!i18n.availableLocales.includes(locale)) {
        locale = i18n.locale;
      }
      return `/${locale}/product/:productNumber`;
    }
  },
  {
    path: '/:locale/product/:productNumber',
    name: 'product',
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/Product/index.vue'),
    beforeEnter(to, from, next) {
      const locale = to.params.locale;
      if (!i18n.availableLocales.includes(locale)) {
        const msg =
          `"${locale}" is not a valid language, or there's no corresponding translation.` +
          ` Redirecting to the "${i18n.locale}" version.`;
        window.alert(msg);
        next({
          name: 'product',
          params: {
            locale: i18n.locale,
            productNumber: to.params.productNumber
          }
        });
      } else {
        i18n.locale = locale;
        document.documentElement.lang = i18n.locale;
        next();
      }
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
