<template>
  <component :is="tagName" :class="$style['content-container']">
    <slot />
  </component>
</template>

<script>
import containerBaseMixin from '@/components/containers/containerBaseMixin';

export default {
  name: 'ContentContainer',
  mixins: [containerBaseMixin]
};
</script>

<style module>
.content-container {
  @apply p-8 box-border;
}
</style>
